import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { ExportToExcel } from './ExportToExcel';

const ReportForm = () => {

    const [taskdate, setTaskdate] = useState("");
    const [company,setCompany] = useState("");
    const [employee,setEmployee] = useState("");
    const [tasks,setTasks] = useState("");
    const [description,setDesc] = useState("");
    const [starts,setStart] = useState("");
    const [ends,setEnds] = useState("");
    const [deadline,setDeadline] = useState("");
    const [status,setStatus] = useState("");

    const clearFields = (event)  => {
        Array.from(event.target).forEach((e) => (e.value = ""));
   }

    const registerTask = (e) =>{
        e.preventDefault();

        if (employee.length == 0) {
            alert('Employee can not be empty')
            return
        }
        if (taskdate.length == 0) {
            alert('Task Date can not be empty')
            return
        }
        if (company.length == 0) {
            alert('Company can not be empty')
            return
        }
        if (tasks.length == 0) {
            alert('Task can not be empty')
            return
        }
        if (starts.length == 0) {
            alert('Start Time can not be empty')
            return
        }
        if (ends.length == 0) {
            alert('End Time can not be empty')
            return
        }
        if (status.length == 0) {
            alert('Status can not be empty')
            return
        }
        

        var dat={
            taskdate: taskdate,
            company: company,
            employee: employee,
            taskname: tasks,
            description: description,
            startime: starts,
            endtime: ends,
            deadline: deadline,
            status: status
        };
        axios.post("https://deliverables.caddemiratesadvertising.com/api/postdata.php", dat).then(response=>{
            if(response.data === 'Success'){
                alert("Task Saved");
            } else {
                alert("Task Saving");
            }
        })
        clearFields(e);
        setTaskdate("");
        setEmployee("");
        setDesc("");
        setCompany("");
        setTasks("");
        setStart("");
        setEnds("");
        setDeadline("");
        setStatus("");
    }


  const current = new Date();
  const date = current.getFullYear() + "-" + ("0"+(current.getMonth()+1)).slice(-2) +"-"+("0" + current.getDate()).slice(-2);
const mydt= ("0" + current.getDate()).slice(-2) + "-" + ("0"+(current.getMonth()+1)).slice(-2) +"-"+current.getFullYear();
  //const date = "2023-06-09";

    const fileName = `Daily Deliverable-${mydt}`;

    const [taskis, setTaskis] = useState([{}]);
useEffect(() => {
    axios.post('https://deliverables.caddemiratesadvertising.com/api/todaydata.php',date).then(res => {
      console.log(res.data)
      setTaskis(res.data)
    }).catch(err => console.log(err));
}, []);
      
  return (
    <>
        <div className='Reportfrm'>
            <h1 className='heads'>Daily Deliverable Form</h1>
            <form>
            <div className='form-row'>
               <div className='form-group col-md-4'>
               <label>Select Employee</label>
                <select onChange={(e)=>{setEmployee(e.target.value)}} className='form-control' name='employee'>
                    <option value=''>Select Employee</option>
                    <option value='Sanoob'>Sanoob</option>
                    <option value='Vineeth'>Vineeth</option>
                    <option value='Pardeep'>Pardeep</option>
                    <option value='Salma'>Salma</option>
                    <option value='Sheila'>Sheila</option>
                    <option value='Bong'>Bong</option>
                </select>
                </div>
                <div className='form-group col-md-4'>
                <label>Task Date</label>
                <input type='date' onChange={(e)=>{setTaskdate(e.target.value)}} name='taskdate' className='form-control' placeholder='Select Taskdate' />
                </div>

                <div className='form-group col-md-4'>
                <label>Company</label>
                <input type='text' onChange={(e)=>{setCompany(e.target.value)}} name='company' className='form-control' placeholder='Enter Company' />
                </div>
            </div>
            <div className='form-row'>
                <div className='form-group col-md-4'>
                <label>Task</label>
                <input type='text' onChange={(e)=>{setTasks(e.target.value)}} name='taskname' className='form-control' placeholder='Enter your Task' />
                </div>
                <div className='form-group col-md-4'>
                <label>Description</label>
                <textarea onChange={(e)=>{setDesc(e.target.value)}} name='description' className='form-control' rows={1} cols={1} />
                </div>
                <div className='form-group col-md-4'>
                <label>Start Time</label>
                <select onChange={(e)=>{setStart(e.target.value)}} className='form-control' name='startime'>
                    <option value=''>Select Time</option>
                    <option value='08.30 AM'>08.30 AM</option>
                    <option value='09.00 AM'>09.00 AM</option>
                    <option value='09.30 AM'>09.30 AM</option>
                    <option value='10.00 AM'>10.00 AM</option>
                    <option value='10.30 AM'>10.30 AM</option>
                    <option value='11.00 AM'>11.00 AM</option>
                    <option value='11.30 AM'>11.30 AM</option>
                    <option value='12.00 PM'>12.00 PM</option>
                    <option value='12.30 PM'>12.30 PM</option>
                    <option value='01.00 PM'>01.00 PM</option>
                    <option value='01.30 PM'>01.30 PM</option>
                    <option value='02.00 PM'>02.00 PM</option>
                    <option value='02.30 PM'>02.30 PM</option>
                    <option value='03.00 PM'>03.00 PM</option>
                    <option value='03.30 PM'>03.30 PM</option>
                    <option value='04.00 PM'>04.00 PM</option>
                    <option value='04.30 PM'>04.30 PM</option>
                    <option value='05.00 PM'>05.00 PM</option>
                    <option value='05.30 PM'>05.30 PM</option>
                    <option value='06.00 PM'>06.00 PM</option>
                </select>
                </div>
                
                </div>
            <div className='form-row'>
            <div className='form-group col-md-4'>
                <label>End Time</label>
                <select onChange={(e)=>{setEnds(e.target.value)}} className='form-control' name='endtime'>
                    <option value=''>Select Time</option>
                    <option value='08.30 AM'>08.30 AM</option>
                    <option value='09.00 AM'>09.00 AM</option>
                    <option value='09.30 AM'>09.30 AM</option>
                    <option value='10.00 AM'>10.00 AM</option>
                    <option value='10.30 AM'>10.30 AM</option>
                    <option value='11.00 AM'>11.00 AM</option>
                    <option value='11.30 AM'>11.30 AM</option>
                    <option value='12.00 PM'>12.00 PM</option>
                    <option value='12.30 PM'>12.30 PM</option>
                    <option value='01.00 PM'>01.00 PM</option>
                    <option value='01.30 PM'>01.30 PM</option>
                    <option value='02.00 PM'>02.00 PM</option>
                    <option value='02.30 PM'>02.30 PM</option>
                    <option value='03.00 PM'>03.00 PM</option>
                    <option value='03.30 PM'>03.30 PM</option>
                    <option value='04.00 PM'>04.00 PM</option>
                    <option value='04.30 PM'>04.30 PM</option>
                    <option value='05.00 PM'>05.00 PM</option>
                    <option value='05.30 PM'>05.30 PM</option>
                    <option value='06.00 PM'>06.00 PM</option>
                </select>
                </div>
                <div className='form-group col-md-4'>
                <label>Deadline</label>
                <input type='date' onChange={(e)=>{setDeadline(e.target.value)}} name='deadline' className='form-control' placeholder='Select Deadline' />
                </div>
                <div className='form-group col-md-4'>
                <label>Select Status</label>
                <select onChange={(e)=>{setStatus(e.target.value)}} className='form-control' name='status'>
                    <option value=''>Select Status</option>
                    <option value='In Progress'>In Progress</option>
                    <option value='Completed'>Completed</option>
                </select>
                </div>
            </div>
            <div className='form-row'>
                <div className='form-group btnclass'>
                    <button onClick={registerTask} className='mybtn' type='submit'>Add Task</button>
                </div>
             </div>
            </form>
        </div>

        <div className='todaydt'>
        <h1 className='heads'>Daily Deliverable - {mydt} </h1>
        <div className='reportex'><ExportToExcel apiData={taskis} fileName={fileName} /></div>
        
        <table className='tableheads'>
          <thead>
            <tr>
              <th>Si.No</th><th>Employee</th><th>Company</th><th>Task</th><th>Description</th><th>Start Time</th><th>End Time</th><th>Deadline</th><th>Status</th>
            </tr>
          </thead>
          <tbody>
          
          {taskis.map((task, index)=>(  
    <tr key={index}><td>{index+1}</td><td>{task.employee}</td><td>{task.company}</td><td>{task.taskname}</td><td>{task.description}</td><td>{task.startime}</td><td>{task.endtime}</td><td>{task.deadline}</td><td>{task.status}</td></tr>
))}
          </tbody>
        </table>
    </div>
    </>
  )
}

export default ReportForm