import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { ExportToExcel } from './ExportToExcel';

const Vineeth = () => {

    var employee= 'Vineeth';

    const fileName = `Daily Deliverable-${employee}`;

    const [tasks, setTasks] = useState([{}]);
useEffect(() => {
    axios.post('https://deliverables.caddemiratesadvertising.com/api/getdata.php',employee).then(res => {
      console.log(res.data)
      setTasks(res.data)
    }).catch(err => console.log(err));
}, []);


const [taskdate, setTaskdate] = useState("");

const [tasklist, setTasklist] = useState([]);
  


//   useEffect(() => {
//     axios.post("http://localhost:8888/api/searchdata.php", dat).then(response=>{
//       console.log(response.data);
//       setTasklist(response.data)
//     }).catch(err => console.log(err));
// }, []);

const searchTask = (e) =>{
  e.preventDefault();
  
  var dat= taskdate;

  axios.post("https://deliverables.caddemiratesadvertising.com/api/searchdata.php", dat).then(response=>{
    console.log(response.data);
    setTasklist(response.data);
  })
}
  return (
    <div>
        <h1 className='heads'>Daily Deliverable - {employee} </h1>
        <div className='reportex'><ExportToExcel apiData={tasklist.length>0 ? tasklist : tasks} fileName={fileName} /></div>
        <div className='filters'>
          <form>
          <div className='form-group'>
                <label>Task Date</label>
                <input type='date' onChange={(e)=>{setTaskdate(e.target.value)}} name='taskdate' className='form-control' placeholder='Select Taskdate' />
          </div>
          <div className='form-group'>
                    <button onClick={searchTask} className='mybtn srcbtn'>Search</button>
           </div>
          </form>
        </div>
        <table className='tableheads'>
          <thead>
            <tr>
              <th>Si.No</th><th>Task Date</th><th>Company</th><th>Task</th><th>Description</th><th>Start Time</th><th>End Time</th><th>Deadline</th><th>Status</th>
            </tr>
          </thead>
          <tbody>
          
          {tasklist.length>0 ? tasklist.map((taskd, index)=>(  
    <tr key={index}><td>{index+1}</td><td>{taskd.taskdate}</td><td>{taskd.company}</td><td>{taskd.taskname}</td><td>{taskd.description}</td><td>{taskd.startime}</td><td>{taskd.endtime}</td><td>{taskd.deadline}</td><td>{taskd.status}</td></tr>
)): tasks.map((task, index)=>(  
    <tr key={index}><td>{index+1}</td><td>{task.taskdate}</td><td>{task.company}</td><td>{task.taskname}</td><td>{task.description}</td><td>{task.startime}</td><td>{task.endtime}</td><td>{task.deadline}</td><td>{task.status}</td></tr>
))}
          </tbody>
        </table>
    </div>
  )
}

export default Vineeth