//import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Container } from 'react-bootstrap';
//import Products from './Components/Products';
import './Dailyreport/Report.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import ReportForm from './Dailyreport/Pages/ReportForm';
import Sanoob from './Dailyreport/Pages/Sanoob';
import Vineeth from './Dailyreport/Pages/Vineeth';
import Pardeep from './Dailyreport/Pages/Pardeep';
import Salma from './Dailyreport/Pages/Salma';
import Sheila from './Dailyreport/Pages/Sheila';
import Bong from './Dailyreport/Pages/Bong';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Link, Outlet } from 'react-router-dom';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />}>
            <Route index element={<ReportForm/>} />
            <Route path="/sanoob" element={<Sanoob/>} />
            <Route path="/vineeth" element={<Vineeth/>} />
            <Route path="/pardeep" element={<Pardeep/>} />
            <Route path="/salma" element={<Salma/>} />
            <Route path="/sheila" element={<Sheila/>} />
            <Route path="/bong" element={<Bong/>} />
        </Route>
    )
);
  return (
    <div className="App">
      <Container>
      <RouterProvider router={router} />
      </Container>
    </div>
  );
}

const Root = () => {
  return(
      <div>
        <nav className='reportarea'>
        <ul>
          <li><Link to="/">Report Form</Link></li>
          <li><Link to="/sanoob">Sanoob</Link></li>
          <li><Link to="/vineeth">Vineeth</Link></li>
          <li><Link to="/pardeep">Pardeep</Link></li>
          <li><Link to="/salma">Salma</Link></li>
          <li><Link to="/sheila">Sheila</Link></li>
          <li><Link to="/bong">Bong</Link></li>
          </ul>
        </nav>
         
          <div>
            <Outlet />
          </div>
      </div>
  )
}

export default App;
